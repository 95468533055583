<template>
    <div class="page-order-details">
        <template v-if="orderData">
            <el-card class="box-card">
                <div slot="header" class="card-header">
                    <span>基础信息</span>
                </div>
                <el-row class="row">
                    <el-col :span="3">订单号:</el-col>
                    <el-col :span="9">
                        <a href="javascript:" @click="$router.push(`/order/list?keywords=${orderData.id}`)"
                            style="text-decoration:underline">{{ orderData.id }}</a>
                    </el-col>
                    <el-col :span="3">状态:</el-col>
                    <el-col :span="9">
                        <el-tag type="warning" size="mini">{{ orderData.statusDesc }}</el-tag>&nbsp;&nbsp;&nbsp;
                        <el-tag v-if="!orderData.isReturned && orderData.status == 8" size="mini">超时订单</el-tag>
                    </el-col>
                </el-row>
                <el-row class="row">
                    <el-col :span="3">租赁地:</el-col>
                    <el-col :span="9">{{ orderData.serviceLocation }}</el-col>
                    <el-col :span="3">租赁地设备ID:</el-col>
                    <el-col :span="9">
                        <a href="javascript:" @click="$router.push(`/device/list?keywords=${orderData.deviceId}`)"
                            style="text-decoration:underline">{{ orderData.deviceId }}</a>
                        <a style="color:red;text-decoration:underline" href="javascript:"
                            @click="$router.push(`/device/terminals?id=${orderData.terminalId}`)">(输液宝ID：{{ orderData.terminalId || '--' }})</a>
                    </el-col>
                </el-row>
                <el-row class="row">
                    <el-col :span="3">归还地:</el-col>
                    <el-col :span="9">{{ orderData.returnLocation || '--' }}</el-col>
                    <el-col :span="3">归还地设备ID：</el-col>
                    <el-col :span="9">
                        <a href="javascript:" @click="$router.push(`/device/list?keywords=${orderData.returnDeviceId}`)"
                            style="text-decoration:underline">{{ orderData.returnDeviceId }}</a>
                    </el-col>
                </el-row>
                <el-row class="row">
                    <el-col :span="3">会员:</el-col>
                    <el-col :span="9">
                        <div class="user-item">
                            <div class="info-wrap">
                                <div class="user-name">{{ orderData.member.name }}</div>
                                /
                                <div class="sub-info">
                                    <div class="user-id">ID:{{ orderData.member.id }}</div>
                                    <el-tag type="danger" size="mini" v-if="orderData.isStaffOrder">员工</el-tag>
                                </div>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="3">押金:</el-col>
                    <el-col :span="9">{{ orderData.depositAmount }}元</el-col>
                </el-row>
                <el-row class="row">
                    <el-col :span="3">实际支付金额:</el-col>
                    <el-col :span="9">
                        <el-tag>{{ orderData.paymentAmount }}元</el-tag>
                    </el-col>
                    <el-col :span="3">使用时长:</el-col>
                    <el-col :span="9">{{ orderData.currentServiceMinutes }}分钟</el-col>
                </el-row>
                <el-row class="row">
                    <el-col :span="3">租赁金额:</el-col>
                    <el-col :span="9">{{ orderData.currentRent }}元</el-col>
                    <el-col :span="3">总退金额:</el-col>
                    <el-col :span="9">{{ orderData.refundAmount || '--' }}元</el-col>
                </el-row>
                <el-row class="row">
                    <el-col :span="3">支付方式:</el-col>
                    <el-col :span="9">{{ orderData.paymentTypeDesc || '--' }}</el-col>
                    <el-col :span="3">交易号:</el-col>
                    <el-col :span="9">{{ orderData.tradeNo || '--' }}</el-col>
                </el-row>
                <el-row class="row">
                    <el-col :span="3">租赁时间:</el-col>
                    <el-col :span="9">{{ orderData.startServiceTime || '--' }}</el-col>
                    <el-col :span="3">结束时间:</el-col>
                    <el-col :span="9">{{ orderData.endServiceTime || '--' }}</el-col>
                </el-row>
                <el-row>
                    <el-col :span="3">备注/原因:</el-col>
                    <el-col :span="9">{{ orderData.remark || '--' }}</el-col>
                    <el-col :span="3">支付方式</el-col>
                    <el-col :span="9">
                        <el-tag type="warning">{{ orderData.paymentTypeDesc }}</el-tag>
                    </el-col>
                </el-row>
                <el-row v-for="item in orderData.refundInfos" :key="item.orderNo">
                    <el-col :span="3">退还金额:</el-col>
                    <el-col :span="9">{{ item.amount }}元，单号:{{ item.orderNo }}</el-col>
                    <el-col :span="3">交易单号:</el-col>
                    <el-col :span="9">{{ item.tradeNo }},退还时间:{{ item.completeTime || '--' }}</el-col>
                </el-row>
            </el-card>
            <br />
            <el-card class="box-card">
                <div slot="header" class="card-header">
                    <span>设备品牌</span>
                </div>
                <el-row>
                    <el-col :span="3">品牌名称</el-col>
                    <el-col :span="9">{{ orderData.deviceType.name }}</el-col>
                    <el-col :span="3">价格</el-col>
                    <el-col :span="9">{{ orderData.rentSettings.freeMinutes }}分钟内免费,
                        <span v-if="orderData.rentSettings.startRent > 0">起价{{ orderData.rentSettings.startRent }}元/{{ orderData.rentSettings.startRentUnitDesc }},</span>
                        超出后{{ orderData.rentSettings.rentPrice }}元/{{ orderData.rentSettings.rentTimeUnitDesc }}
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="3">押金</el-col>
                    <el-col :span="9">{{ orderData.deviceType.deposit }}元</el-col>
                    <el-col :span="3">日封顶</el-col>
                    <el-col :span="9">{{ orderData.rentSettings.maxDayRent }}元</el-col>
                </el-row>
            </el-card>
            <br />
            <el-card class="box-card" v-if="orderData.abnormality">
                <div slot="header" class="card-header">
                    <span>异常反馈</span>
                </div>
                <el-row>
                    <el-col :span="3">内容</el-col>
                    <el-col :span="9">{{ orderData.abnormality.message }}</el-col>
                    <el-col :span="3">状态</el-col>
                    <el-col :span="9">{{ orderData.abnormality.statusDesc }}</el-col>
                </el-row>
                <el-row>
                    <el-col :span="3">联系电话</el-col>
                    <el-col :span="9">
                        <el-col :span="9">{{ orderData.abnormality.contactTel }}</el-col>
                    </el-col>
                    <el-col :span="3">图片</el-col>
                    <el-col :span="9">
                        <a target="_blank" :href="item" v-for="item in orderData.abnormality.images" :key="item">
                            <img :src="item" width="60px" height="60px" alt />
                        </a>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="3">提交时间</el-col>
                    <el-col :span="9">{{ orderData.abnormality.creationTime }}</el-col>
                    <template v-if="orderData.abnormality.handledAdminUserName">
                        <el-col :span="3">处理人</el-col>
                        <el-col :span="9">{{ orderData.abnormality.handledAdminUserName }}</el-col>
                    </template>
                </el-row>
            </el-card>
        </template>
        <template v-else>加载中...</template>
    </div>
</template>

<script>
import * as funOrder from "@/api/order/index"
export default {
    name: "pageOrderDetails",
    data() {
        return {
            id: "",
            orderData: {}
        }
    },
    created() {
        this.id = this.$route.query.id
        this.readerOrderInfo()
    },
    activated() {
        if (this.id != this.$route.query.id) {
            this.id = this.$route.query.id
            this.readerOrderInfo()
        }
    },
    methods: {
        async readerOrderInfo() {
            await funOrder.GetOrderDetail({ id: this.id }).then(res => {
                this.orderData = res
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.page-order-details {
    .card-header {
        display: flex;
        justify-content: space-between;
    }

    .user-item {
        display: flex;
        align-items: center;

        .avatar {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-position: center;
            background-size: cover;
            margin-right: 5px;
        }

        .user-name {
            font-weight: bold;
        }

        .info-wrap {
            flex: 1;
            overflow: hidden;
            display: flex
        }

        .sub-info {
            display: flex;
            align-items: center;

            .user-id {
                margin-right: 4px;
            }
        }

    }
    .row{
        border-bottom: 1px solid #EBEEF5;
        padding-bottom: 10px
    }
    .el-row {
        margin-bottom: 20px;
    }
}
</style>